// Lazy load video embeds

(function( $ ) {
	
	let $videos = $('[data-video]');

	function getVideoTag( video, poster ) {
		return `<video autoplay loop muted playsinline src="${video}" poster="${poster}"></video>`;
	}

	function loadVideo( img ) {
		let video = img.getAttribute('data-video');
		let poster = img.getAttribute('src');

		let html = getVideoTag(video, poster);

		$(img).replaceWith(html);
	}

	$videos.each((index, el) => {
		loadVideo(el);
	});

})( jQuery );
