( function( $, window, document, undefined ) {

	const $latest_news = $('.latest-news__slider');
	const $latest_news_next = $('.latest-news__arrow--next');
	const $latest_news_prev = $('.latest-news__arrow--prev');

	if ( $latest_news ) {
		$latest_news.slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			arrows: true,
			nextArrow: $latest_news_next,
			prevArrow: $latest_news_prev,
			dots: true,
			responsive: [
			    {
			      breakpoint: 1199,
			      settings: {
			        slidesToShow: 1
			      }
			    }
			]
		});
	}

} )( jQuery, window, document );
