( function( $, window, document, undefined ) {

	const $header = $('.site-header');
	const $body = $('body');
	const $page_title = $('.page-title');
	const $hero = $('.hero');
	const $is_mobile = $('.is-mobile');
	var header_height;

	$(window).on('scroll load resize', function () {
		
		header_height = $header.outerHeight();
		var sticky_height = header_height + 600;
		
		var scroll_top = $(window).scrollTop();

		if ( !isMobile() ) {

			if ( scroll_top >= sticky_height && !$header.hasClass('sticky') ) {
				$body.css('padding-top', header_height + 'px');
				$header.addClass('sticky');
			} else if ( scroll_top < sticky_height && $header.hasClass('sticky') ) {
				$header.addClass('slide-up');
				setTimeout(function(){ 
					$header.removeClass('slide-up');
					$header.removeClass('sticky');
					$body.css('padding-top', '0');
				}, 200);
			}

		}
	});

	$(window).on('load resize', function () {

		if ( $page_title.length && $hero.length ) {
			var pagetitle_height = $page_title.outerHeight();
			$hero.css('height', 'calc(100vh - ' + ((pagetitle_height + header_height) + 25) +  'px)');
		} else if ( $hero.length ) {
			$hero.css('height', 'calc(100vh - ' + (header_height + 25) +  'px)');
		}
		
	});

	function isMobile() {
		if ( $is_mobile.css('visibility') == 'visible' ) {
			return true;
		} else {
			return false;
		}
	}


} )( jQuery, window, document );
