( function( $, window, document, undefined ) {

	var rellax;

	if ( !$('.rellax').length ) {
		return;
	}

	$(window).on('load', function() {
		rellax = new Rellax('.rellax', {
			center: true,
			breakpoints: [576, 768, 1201]
		});
	});

	// $(window).on('load resize', function() {
	// 	if (window.matchMedia("(max-width: 768px)").matches) {
	// 		rellax.destroy();
	// 	} else {
	// 		rellax.refresh();
	// 	}
	// });

} )( jQuery, window, document );
