(function () {

  const checkpoint = 500;
  const fade = document.querySelector(".fade-on-scroll");

  if ( !fade ) {
    return;
  }
   
  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= checkpoint) {
      opacity = 1 - currentScroll / checkpoint;
    } else {
      opacity = 0;
    }
    fade.style.opacity = opacity;
  });

  document.addEventListener('aos:in:hero-heading', ({ detail }) => {
  	setTimeout(function(){ 
  		fade.style.transitionProperty = 'transform';
  	}, 800);
  });
  
})();
