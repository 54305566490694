( function( $, window, document, undefined ) {

	const $hamburger = $('.hamburger');
	const $menu = $('.menu-wrapper');
	var menu_open = false;

	$hamburger.on('click', function() {
		if ( menu_open == false ) {
			openMenu();
		} else {
			closeMenu();
		}
	});

	function openMenu() {
		$hamburger.addClass('is-active');
		$menu.addClass('is-open');
		menu_open = true;
		bodyScrollLock.disableBodyScroll($menu.get(0));
	}

	function closeMenu() {
		$hamburger.removeClass('is-active');
		$menu.removeClass('is-open');
		menu_open = false;
		bodyScrollLock.enableBodyScroll($menu.get(0));
	}

} )( jQuery, window, document );
