var pItems = document.querySelectorAll('.parallax');

imagesLoaded(pItems, function(instance) {
	new simpleParallax(pItems, {
	    delay: 0,
	    orientation: 'down',
	    scale: 1.3,
	    overflow: false
	});
});
